export default {
    data() {
        return {
            orderNo: '',
            evectionType: 2
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo || ''
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        goDetail() {
            if (this.orderNo) {
                this.$router.replace({
                    name: 'admin-hotel-order-detail',
                    query: {
                        orderNo: this.orderNo,
                        evectionType: this.evectionType,
                    }
                })
            }
        },
    }
}